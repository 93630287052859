<template>
  <label for="">
    <v-btn class="ma-0" :color="color" @click="clickInput" :block="block" :flat="flat">
      <v-icon left dark> cloud_upload </v-icon>
      {{ name }}
    </v-btn>
    <input ref="input" type="file" @change="loadTextFromFile" style="display:none;">
  </label>
</template>

<script>
export default {
  name: 'FileReader',
  props: {
    getFile: {
      type: Function,
    },
    color: {
      type: String,
      default: 'accent',
    },
    name: {
      type: String,
      default: 'Buscar Archivo',
    },
    block: {
      type: Boolean,
      default: true,
    },
    flat: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    clickInput() {
      this.$refs.input.click();
    },
    loadTextFromFile(ev) {
      const file = ev.target.files[0];
      this.getFile(file);
      const reader = new FileReader();

      reader.onload = e => this.$emit('load', e.target.result);
      reader.readAsDataURL(file);
    },
  },
};
</script>
