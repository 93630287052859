<template>
  <v-layout row wrap justify-center align-start>
    <v-flex xs12 lg12 md12 class="mb-3">
      <v-card>
        <titleCard title="Repartidores" subtitle="Listado de repartidores asignados a la tienda"></titleCard>
        <v-divider></v-divider>
        <v-card-text>
          <v-layout row wrap text-xs-center>
            <v-flex xs12>
              <v-data-table
                :headers="headers"
                :items="items"
                class="elevation-0 order-table"
                color="secondary"
                :disable-initial-sort="true"
                no-data-text="No hay repartidores ingresados"
                :pagination.sync="pagination"
                :rows-per-page-items="rowsPerPage"
              >
                <template slot="items" slot-scope="props">
                  <tr>
                    <td class="text-xs-left">
                      <v-avatar v-if="props.item.photo != ''" :size="60" color="grey lighten-4" class="mt-2">
                        <img :src="props.item.photo" alt="avatar" />
                      </v-avatar>
                      <h3 class="body-2 mt-1 mb-0" v-html="props.item.name"></h3>
                      <span v-html="props.item.ranking"></span>
                    </td>
                    <td class="text-xs-left">
                      <span v-html="props.item.email"></span>
                    </td>
                    <td @click="changeStatus(props.item)">
                      <app-status :value="props.item.status"></app-status>
                    </td>
                    <td class="text-xs-right">
                      <v-icon
                        v-if="user.role.id === 1 || user.role.id === 2"
                        small
                        class="mr-2"
                        @click="editItem(props.item)"
                      >
                        edit
                      </v-icon>
                      <v-icon
                        v-if="user.role.id === 1 || user.role.id === 2"
                        small
                        class="mr-2"
                        @click="goRoute(props.item.id)"
                      >
                        directions
                      </v-icon>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
    <v-dialog v-model="dialogItem" max-width="500px">
      <v-card>
        <app-title-dialog
          :title="`${item.id ? 'Modificar repartidor' : 'Nuevo Repartidor'}`"
          :close-action="() => (dialogItem = false)"
          close
        >
        </app-title-dialog>
        <v-container class="pa-3" grid-list-lg>
          <v-layout row wrap>
            <v-flex xs12>
              <v-text-field v-model="item.name" label="Nombre" hide-details></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field v-model="item.email" label="Email" hide-details></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field v-model="item.rut" label="Rut" hide-details></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field v-model="item.pass" label="Contraseña" hide-details></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-select
                v-model="item.id_car"
                :items="cars"
                :item-text="it => `${it.patente} - ${it.marca} ${it.modelo}`"
                item-value="id"
                label="Vehiculo por defecto"
                hide-details
              ></v-select>
            </v-flex>
            <v-flex xs6>
              <v-switch v-model="item.status" label="Activo" hide-details class="mt-1"> </v-switch>
            </v-flex>
            <v-flex xs6>
              <v-switch v-model="item.caja" label="Activar Caja" hide-details class="mt-1"> </v-switch>
            </v-flex>
            <v-flex xs6>
              <v-img v-if="closePhoto !== ''" class="mb-2" :src="closePhoto" style="width:200px; height: 200px;" />
              <FileReader
                v-if="closePhoto == ''"
                :name="'Foto de perfil'"
                :flat="false"
                :get-file="this.getFile"
                @load="closePhoto = $event"
              ></FileReader>
              <v-btn v-else color="error" block @click.native="closePhoto = ''">
                Quitar
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="greyback" flat @click.native="dialogItem = false">
            Volver
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="accent" flat @click="saveItem"> Listo </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn fab dark fixed bottom right color="accent" @click="editItem({})">
      <v-icon>add</v-icon>
    </v-btn>
  </v-layout>
</template>

<script>
import { MI_STORE } from '../../config'
import titleCard from '../useful/titleCard.vue'
import appStatus from '../useful/status.vue'
import appTitleDialog from '../useful/titleDialog.vue'
import FileReader from '../useful/FileReader.vue'

export default {
  name: 'StoreDealers',
  components: {
    titleCard,
    appStatus,
    appTitleDialog,
    FileReader
  },
  data: () => ({
    closePhoto: '',
    dialogItem: false,
    headers: [
      { text: 'Nombre', align: 'left', sortable: false },
      { text: 'Email', align: 'left', sortable: false },
      { text: 'Estado', align: 'center', sortable: false },
      { text: '', align: 'right', sortable: false }
    ],
    items: [],
    item: {},
    fileUpload: null,
    pagination: { rowsPerPage: 50 },
    rowsPerPage: [25, 50, 100, 200, { text: 'Todos', value: -1 }]
  }),
  computed: {
    user() {
      return this.$store.getters.getUser
    },
    cars() {
      return this.$store.getters.getCars
    }
  },
  mounted() {
    console.log('mounted')
    this.getItems()
  },
  methods: {
    async getItems() {
      try {
        const res = await this.$http.get(`${MI_STORE}/dealers`)
        this.items = res.data
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async saveItem() {
      const formData = new FormData()
      formData.append('file', this.fileUpload)
      formData.append('item', JSON.stringify(this.item))
      const self = this
      try {
        await this.$http.post(`${MI_STORE}/dealers`, formData, {
          headers: { 'content-type': 'multipart/form-data' }
        })
        this.closePhoto = ''
        this.item = []
        this.dialogItem = false
        this.getItems()
        self.$store.dispatch('changeSnack', {
          active: true,
          text: 'Subido con exito...'
        })
      } catch (error) {
        self.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente...'
        })
      }
    },
    getFile(file) {
      this.fileUpload = file
    },
    changeStatus(item) {
      this.item = item
      this.item.status = !this.item.status
      this.saveItem()
    },
    editItem(item) {
      if (item) {
        this.item = item
      }
      this.closePhoto = item.photo ? item.photo : ''
      this.dialogItem = true
    },
    goRoute(uid) {
      this.$router.push({ name: 'storeDealersRoutes', query: { repartidor: uid } })
    }
  }
}
</script>

<style></style>
